export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Nuestros clientes</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div className="col-md-6 img-responsive team geeks">
                  {" "}
                  <a href={d.href} target="_blank">
                    <input
                      className="img-responsive"
                      type="image"
                      src={d.img}
                      alt=""
                      width={500}
                    />{" "}
                  </a>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
