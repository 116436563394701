export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Transformación para tu organización</h2>
          <p>
            Diseñamos, desarrollamos e implementamos todos los cambios y las
            soluciones que tu empresa requiere.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h2>{d.name}</h2>
                    <h3>{d.text}</h3>
                  </div>
                  <div className="list-style">
                    <ul>
                      {d.bullet
                        ? d.bullet.map((d, i) => <li key={`${d}-${i}`}>{d}</li>)
                        : "loading"}
                    </ul>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <a
          href="docs/folleto.pdf"
          target="_path"
          className="btn btn-custom btn-lg page-scroll "
        >
          Folleto
        </a>{" "}
      </div>
    </div>
  );
};
