export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contáctanos</h2>
                <p>
                  Por favor, mandanos un correo. Nosotros te responderemos lo
                  más pronto posible.
                </p>
              </div>
              <a href="mailto:sgodinez@mithikel.com; cperez@mithikel.com; igodinez@mithikel.com?subject=God-Leg">
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar correo
                </button>
              </a>
              <div className="col-md-12">
                <div className="row"></div>
                <a href="https://mithikel.com/" target="_blank">
                  <input className="geeks " type="image" src="img/mithikel.svg" width={250} />
                  <p>
                    Nuestra empresa hermana, desarrolladora de tecnologías de
                    alta disponibilidad.
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Información de contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div> */}
          </div>

          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
