export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>¿Quiénes somos?</h2>
              <p>
                Somos una empresa consultora que{" "}
                <b>integra el negocio y la tecnología</b>, para lograr la
                transformación digital de las organizaciones.
              </p>
              <p>
                Nuestra meta es ayudar a nuestros clientes en{" "}
                <b>
                  reconocer sus procesos de negocio reales, medir su estado
                  actual y poder tomar decisiones para optimizar los mismos
                </b>
                , considerando siempre la
                <b>mejora continua</b>, materializando su crecimiento y
                aumentando su excelencia operativa; una vez que las
                organizaciones conocen sus procesos{" "}
                <b>
                  los acompañamos a la transformación digital de su
                  organización, ahorrándoles tiempo y dinero
                </b>
                .
              </p>
              <p>
                Entendemos los problemas actuales que enfrentan las
                organizaciones; nuestros clientes se cuestionan sobre su estado
                actual y buscan crecimiento.{" "}
              </p>
              <p>
                Nuestra estrategia de trabajo nos permite colaborar codo a codo
                con nuestros clientes buscando la transformación de su empresa,
                utilizando <b>soluciones a la medida</b> considerando la visión
                de negocio de cada organización.
              </p>

              {/* {props.data
                ? props.data.paragraph.map((d, i) => (
                    <p key={`${d}-${i}`}>{d}</p>
                  ))
                : "loading"} */}
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <h3>¿Por qué debes elegirnos?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>Certificación Lean Six Sigma (mejora continua de procesos)</li>
                    <li>Vasta experiencia en desarrollo de sistemas críticos de información</li>
                    <li>Planeación y desarrollo en negocios complejos</li>

                    {/* {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"} */}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>Hemos diseñado y desarrollado sistemas financieros</li>
                    <li>Tecnologías de alta disponibilidad y tolerante a fallas</li>
                    <li>Utilización y desarrollo de tecnologías de punta</li>
                    {/* {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
